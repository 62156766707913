import router from '@/router/index';
import {encode, decode} from '@/utils/base64.js'

export function isValidParameter(data) {
  if(data.mobile && data.amount && data.PO && data.CRN && data.MC && data.sig && data.timestamp) {
    const amountOptions = convertParamStringToArray(data.amount)
    const paymentOptions = convertParamStringToArray(data.PO)
    if(amountOptions && paymentOptions && amountOptions.length === paymentOptions.length) {
      return true
    }
  }
  return false
}

export function isValidV2Parameter(data) {
  console.log(data)
  if(data.mobile && data.amount && data.PO && data.CRN && data.MC && data.sig && data.timestamp && data.AN && data.token) {
    const decryptToken = decode(window.atob(data.token))
    const tokenValues = decryptToken.split('|')
    console.log(tokenValues)
    if(tokenValues.length != 8) {
      return false
    }

    if(tokenValues[4] != data.AN) {
      return false
    }

    if(tokenValues[5] != data.MC) {
      return false
    }

    const amountOptions = convertParamStringToArray(data.amount)
    const paymentOptions = convertParamStringToArray(data.PO)
    if(amountOptions && paymentOptions && amountOptions.length === paymentOptions.length && amountOptions.length === 1) {
      if(parseFloat(amountOptions[0]) != parseFloat(tokenValues[2])) {
        return false
      }

      if(paymentOptions[0] != tokenValues[0]) {
        return false
      }

      return true
    }
  }
  return false
}

export function isValidOptionParameter(data) {
  if(data.mobile && data.amount && data.MC && data.sig && data.timestamp) {
    return true
  }
  return false
}

export function invalidParameterErrorCode(data, type = "PAYMENT") {
  if(data) {
    if(!data.mobile) return "10000"
    if(!data.amount) return "10001"
    if(!data.MC) return "10004"
    if(!data.sig) return "10005"
    if(!data.timestamp) return "10006"
    if(type === "PAYMENT") {
      if(!data.PO) return "10002"
      if(!data.CRN) return "10003"
      if(!data.AN) return "10009"
      if(!data.token) return "10010"
      const amountOptions = convertParamStringToArray(data.amount)
      const paymentOptions = convertParamStringToArray(data.PO)
      if(amountOptions && paymentOptions && amountOptions.length !== paymentOptions.length) {
        return "10007"
      } else if(!amountOptions || !paymentOptions) {
        return "10007"
      }
    }
    return ""
  }
}

export function isValidPinDetails(data) {
  if(data.amount && data.merchantCode && data.merchantReference && data.mobile && data.paymentCode && data.signature && data.timestamp) {
    return true
  }
  return false
}

export function getDetailsFromToken(data) {
  const decryptToken = decode(window.atob(data.token))
  const tokenValues = decryptToken.split('|')
  return tokenValues
}

export function convertParamStringToArray(string) {
  try {
    return string.replace("[", "").replace("]", "").split(",")
  } catch(error) {
    return []
  }
}

export function getBalanceBreakdownWithProvidedAN(accountNumbers) {
  var balanceBreakdown = []
  if(accountNumbers) {
    const list = convertParamStringToArray(accountNumbers)
    for(const index in list) {
      balanceBreakdown.push({
        key: list[index],
        value: "N/A"
    })
    }
  }
  return balanceBreakdown
}

export function getBalanceBreakdown(list) {
  var balanceBreakdown = []
  list.filter(item => {
    if (item !== 'CashOnPickup') {
        balanceBreakdown.push({
            key: item,
            value: 0.00
        })
    }
  })
  return balanceBreakdown
}

export function getBalanceBreakdownV2(list, paymentOptions) {
  var balanceBreakdown = []
  list.filter(item => {
    if(item[paymentOptions.code]) {
      const value = item[paymentOptions.code]
      value.filter(account => {
        balanceBreakdown.push({
          key: account.accountNumber,
          value: account.balance
        })
      })
      return
    }
  })
  return balanceBreakdown
}

export function updateBalanceBreakdown(list, type, amount) {
  let breakdown = list.slice();
  let index = -1
  var item = breakdown.find((x, i) => {
    if(x.key == type) {
      index = i
      return x
    }
  });
  if (item) {
    if(!amount) {
      breakdown.splice(index, 1)
    } else {
      item.value = amount
    }
  }
  return breakdown
}

export function updateBalanceBreakdownValue(list, type, amount) {
  let breakdown = list.slice();
  let index = -1
  var item = breakdown.find((x, i) => {
    if(x.key == type) {
      index = i
      return x
    }
  });
  if (item) {
    item.value = amount
  }
  return breakdown
}

export function getPaymentOption(list, index) {
  const paymentOption = list[index] ? list[index].key : ''
  return paymentOption
}

export function getAmountToPay(paymentOptionList, amountList, paymentOption) {
  const index = paymentOptionList.findIndex(x => x.trim() === paymentOption)
  const amount = amountList[index] ? amountList[index] : ''
  return amount
}

export function getBalanceParameters(data, paymentOption, amount) {
  const params = {
    mobile: data.mobile,
    paymentOptions:data.PO,
    paymentCode: paymentOption,
    merchantCode: data.MC,
    signature: data.sig,
    amount: amount,
    amountOptions: data.amount,
    merchantReference: data.CRN,
    timestamp: data.timestamp
  }
  return params
}

export function getBalanceV2Parameters(data, paymentOption, type = "options") {
  const params = {
    mobile: data.mobile,
    paymentCode: paymentOption.code,
    merchantCode: data.MC,
    signature: type === 'options' ? data.sig : data.sig2,
    amount: type === 'options' ? data.amount : paymentOption.transactionAmount,
    timestamp: type === 'options' ? data.timestamp : paymentOption.timestamp
  }
  console.log(params, data, type)
  return params
}

export function getChargeParameters(data, paymentOption, amount, pin) {
  const params = {
    mobile: data.mobile,
    paymentOptions:data.PO,
    paymentCode: paymentOption,
    merchantCode: data.MC,
    signature: data.sig,
    amount: amount,
    amountOptions: data.amount,
    merchantReference: data.CRN,
    timestamp: data.timestamp,
    pin: pin
  }
  return params
}

export function getChargeParametersV2(data, selectedPaymentOption) {
  const params = {
    mobile: data.mobile,
    paymentOptions:data.PO,
    paymentCode: selectedPaymentOption.code,
    merchantCode: data.MC,
    signature: data.sig,
    amount: selectedPaymentOption.amountToPay,
    amountOptions: data.amount,
    merchantReference: data.CRN,
    timestamp: data.timestamp,
    accountNumber: data.AN,
    accountName: data.accountName,
    transactionType: data.transactionType,
    email: data.email,
  }
  return params
}

export function isValidPaymentOption(paymentOption) {
  //   const validOptions = ['CliqqWallet', 'CliqqPoints']
  //   if(validOptions.includes(paymentOption)) {
  //     return true
  //   }
  //   return false
  // }
  return true
}

export function splitCamelCaseToString(string) {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function getAmountLabel(code, paymentOption, merchants) {
  if(paymentOption === 'CashOnPickup') {
    return '₱'
  }

  const paymentMethods = merchants ? merchants[code].paymentMethods ?? [] : []
  if(paymentMethods.length > 0) {
    var item = paymentMethods.find(x => x.code == paymentOption);
    if(item && item.currency && item.currency === 'PHP') {
      return '₱'
    } else if(item && item.currency) {
      return ''
    }
  }

  const notPeso = ['CliqqPoints', 'ZeepPoints']
  if (notPeso.includes(paymentOption)) {
      return ''
  } else {
      return '₱'
  }
}

export function getDefaultpaymentOptionLabel(list, merchants, mc) {
  var option = ''
  for (const i in list) {
    var name = splitCamelCaseToString(list[i])
    if(merchants[mc] != undefined) {
      try {
        name = getPaymentMethodName(list[i], merchants[mc].paymentMethods)
      } catch(error) {
        console.log(error)
      }
      if (option === '') {
          option += name
      } else {
          option += ' + ' + name
      }
    }
  }
  return option
}

export function formatAmount(amount) {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  if(amount) {
    return Number(amount).toLocaleString('en', options)
  }
  return '00.00'
}

export function successCharge(merchants, data, url) {
  var merchantName = data.MC
  if(merchants && merchants[data.MC]) {
    merchantName = merchants[data.MC].merchantName
  }
  return {
    dialogTitle: "PAYMENT SUCCESSFUL",
    dialogMessage: `Thank you for your business with <br><strong>${merchantName}</strong><br><br>Your transaction reference is <br><strong>${data.CRN}</strong>`,
    redirectUrl: url
  }
}

export function successChargeV2(data, response) {
  var merchantName = data.MC
  if(response && response.merchantName) {
    merchantName = response.merchantName
  }
  return {
    dialogTitle: response.transactionStatus,
    dialogMessage: `Thank you for your business with <br><strong>${merchantName}</strong><br><br>Your transaction is recorded with reference ID <br><strong>${data.CRN}</strong><br><br> Please wait for few moments to process your payment.`,
    redirectUrl: response.successUrl
  }
}

export function errorCharge(response, url) {
  var message = "Sorry, error in processing your payment."
  if (response.error) {
    console.log(response.error)
      message = response.error
      let sub = message.split("exceeds limit,")
      console.log(sub,sub.length)
      if(sub.length > 1) {
        message = sub[0] + "exceeds limit. ]"
      }
  }
  return {
    dialogTitle: "ERROR",
    dialogMessage: `${message}`,
    redirectUrl: url
  }
}

export function isError500(redirectUrl) {
  if (redirectUrl && redirectUrl.length > 0) {
    return false
  } else {
      return true
  }
}

export function shouldRedirectbackToMerchant(message) {
  if(message.toLowerCase().includes('invalid pin')) {
    return false
  }
  return true
}

export function getPaymentMethodName(code, list) {
  var item = list.find(x => x.code == code);
  return item.name
}
